import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";
import { Token2049Icon } from "./Token2049Icon";

const BANNER_LINK_TEXT = "Meet Gotbit team in Dubai during";

const BANNER_LINK_LINK = "https://www.eventbrite.com/e/crypto-og-party-tickets-878179246767";

export interface BannerLinkProps extends ComponentPropsWithoutRef<"div"> {}

export const BannerLink = (props: BannerLinkProps) => (
  <styles.BannerLinkWrapper {...props}>
    <styles.LinkWrapper href={BANNER_LINK_LINK} target="_blank" rel="noopener noreferrer">
      {BANNER_LINK_TEXT}
      <Token2049Icon />
      <styles.LinkLaunchIcon />
    </styles.LinkWrapper>
  </styles.BannerLinkWrapper>
);
