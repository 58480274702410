import Icons from "src/icons/Icons";
import styled from "styled-components";

export const BannerLinkWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.contentBackgroundColor};

  svg {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  position: relative;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 1px;
    background-color: ${({ theme }) => theme.primaryColor};
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const LinkLaunchIcon = styled(Icons.Launch)`
  color: ${({ theme }) => theme.primaryColor};
`;
