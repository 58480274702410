import styled from "styled-components";
import { BannerLink } from "./BannerLink";

export const HeaderContainer = styled.header`
  width: 100%;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  background-color: ${({ theme }) => theme.contentBackgroundColor};
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
`;

export const HeaderBody = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.headerHeight};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;
  padding: 3px 10px;
`;

export const HeaderCaption = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 0px;

  border-bottom: 1px solid ${({ theme }) => theme.disableInputBgColor};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 32px;
  padding: 0px 12px;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px;
`;

export const MobileBannerLink = styled(BannerLink)`
  justify-content: center;
`;
